<!--新增地址-->
<template>
 <div class="addAddress">
    <van-form validate-first ref="addressForm" @failed="onFailed">
      <van-field
        v-model="name"
        name="name"
        label="收货人"
        :maxlength="15"
        placeholder="请输入姓名"
      />
      <van-field
        v-model="phone"
        name="phone"
        label="电话号码"
        placeholder="请输入电话号码"
      />
      <!-- <van-field
        v-model="provinceName"
        name="provinceName"
        label="所在地区"
        placeholder="请输入所在地区"
        :rules="[{ provinceName, message: '请输入正确内容' }]"
      /> -->
      <van-field
        readonly
        clickable
        name="area"
        :value="addressName"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-field
        v-model="detailAddress"
        name="detailAddress"
        label="详细地址"
        placeholder="请输入详细地址"
      />
    </van-form>
    <!-- <div class="infoList">
      <span>收货人</span>
      <input type="text" placeholder="请输入姓名" />
    </div>
    <div class="infoList">
      <span>电话号码</span>
      <input type="text" placeholder="请输入电话号码"/>
    </div>
    <div class="infoList">
      <span>所在地区</span>
      <input type="text" class="selectArea" placeholder="请输入所在地区"/>
      <span class="arrow"> <img src="@/assets/images/h5-icon/arrow-right.png"></span>
    </div>
    <div class="infoList">
      <span>详细地址</span>
      <input type="text" placeholder="请输入详细地址" />
    </div> -->
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :area-list="province"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
    <div class="save-btn" @click="confirm">
      <span>保存</span>
    </div>
  </div>
</template>
<script>
import province from '@/utils/province.js'
import { addAddress, updateAddress } from '@/api/api'

export default {
  name:"addAddress",
  data() {
    return {
      id: null,
      name: '',
      phone: '',
      provinceName: '',
      cityName: '',
      regionName: '',
      detailAddress: '',
      showArea: false,
      province: province,
      addressName: '',
      phonePattern: /^1[34578]\d{9}$/
    }
  },
  mounted() {
    this.getEditAddress()
  },
  methods: {
    getEditAddress() {
      let item = window.localStorage.getItem('editAddress') || ''
      if(item) {
        let address = JSON.parse(item)
        this.name = address.name
        this.phone = address.phone
        this.addressName = address.provinceName ? `${address.provinceName} ${address.cityName} ${address.regionName}` : ''
        this.detailAddress = address.detailAddress
        this.id = address.id
      }
    },
    check() {
      let state = true
      if(!this.name) {
        this.$toast('收货人不能为空')
        state = false
        return false
      }
      if(!this.phone) {
        this.$toast('电话号码不能为空')
        state = false
        return false
      }
      if(!this.addressName) {
        this.$toast('请选择地区')
        state = false
        return false
      }
      if(!this.detailAddress) {
        this.$toast('详细地址不能位空')
        state = false
        return false
      }
      if(!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast('手机号码格式不正确');
        state = false
        return false
      }
      return state
    },
    // 
    async confirm() {
      // this.$refs.addressForm.validate()
      // this.$refs.addressForm.submit()
      if(!this.check()) {
        return false
      }
      let params = {
        name: this.name,
        phone: this.phone,
        provinceName: this.addressName.split(' ')[0],
        cityName: this.addressName.split(' ')[1],
        regionName: this.addressName.split(' ')[2],
        detailAddress: this.detailAddress,
      }
      // console.log(params)
      if(this.id) {
        params.id = this.id
        this.updateAddress(params)
      } else {
        this.addAddress(params)
      }
    },
    async updateAddress(params) {
      let response = await updateAddress(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      window.localStorage.removeItem('editAddress')
      this.$toast(response.returnMsg)
      this.$router.go(-1)
    },
    async addAddress(params) {
      
      let response = await addAddress(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.$toast(response.returnMsg)
      this.$router.go(-1)
    },
    onFailed(errorInfo) {
      // console.log('failed', errorInfo);
    },
    pattern(val) {
      return /^1[34578]\d{9}$/.test(val);
    },
    onConfirm(e) {
      this.addressName = ''
      e.forEach(element => {
        this.addressName += `${element.name} `
      });
      this.showArea = false
    }
  }
} 
</script>
<style scoped>
.addAddress{
  /* width:94%; */
  padding:0.2rem 3%;
  background:#fff;
}
.addAddress .title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 0.5rem;
  padding:0.2rem 0;
  border-bottom:1px #f2f2f5 solid;
}
.addAddress .infoList{
  display: flex;
  font-size: 14px;
  color:#5B6B73;
  line-height: 0.6rem;
  text-align: left;
  margin:0.15rem 0;
  border-bottom: 1px #f2f2f5 solid;
  display: flex;
  align-items: center;
}
.addAddress .infoList span{
  width:30%;
}
.addAddress .infoList .selectArea{
  flex-grow:2
}
.addAddress .infoList .arrow{
  width: 0.2rem;
}
.addAddress .infoList .arrow img{
  width:0.17rem;
  height:0.3rem;
}
.save-btn span{
  display: block;
  width:94%;
  height:0.8rem;
  line-height: 0.8rem;
  background:#FF3F47;
  font-size: 14px;
  color:#fff;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  margin:1rem 3%;
}
</style>